/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from "antd";
import { Locale } from "antd/lib/locale-provider";
import localeHR from "antd/lib/locale/hr_HR";
import axios from "axios";
import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationPaths } from "../components/api-authorization/ApiAuthorizationConstants";
import authService from "../components/api-authorization/AuthorizeService";
import { LoadingOverlay } from "../components/loading-overlay/LoadingOverlay";
import { translations } from "../config/translations";
import { Language, UserRole } from "../core/models/Enum";
import { AuthorizedUserModel } from "../core/models/Person";
import { getRoleFromRoleText } from "./PersonHelper";

interface Props {
  children: ReactElement | null;
}

export interface IUseAuthValues {
  language: Language;
  loading: boolean;
  url: string;
  locale: Locale;
  userRole?: UserRole;
  userPersonId?: number;
  userBrandId?: number;
  isAuthenticated: boolean;
  get: <T>(url: string) => Promise<T | undefined>;
  post: <T>(
    url: string,
    body: any,
    hideNotification?: boolean
  ) => Promise<T | undefined>;
  postFile: <T>(url: string, body: any) => Promise<T | undefined>;
  put: <T>(url: string, body: any) => Promise<T | undefined>;
  remove: <T>(url: string) => Promise<T | undefined>;
  setLanguage: (newLanguage: Language) => void;
  setLoading: (newLoading: boolean, newMessage?: string) => void;
  setUrl: (currentUser: string) => void;
  setLocale: (newLocale: Locale) => void;
}

const defaultState: IUseAuthValues = {
  language: Language.Croatian,
  loading: false,
  url: "",
  locale: localeHR,
  isAuthenticated: false,
  get: (url: string) => {
    throw new Error("Function not implemented!");
  },
  post: (url: string, body: any, hideNotification?: boolean) => {
    throw new Error("Function not implemented!");
  },
  postFile: (url: string, body: any) => {
    throw new Error("Function not implemented!");
  },
  put: (url: string, body: any) => {
    throw new Error("Function not implemented!");
  },
  remove: (url: string) => {
    throw new Error("Function not implemented!");
  },
  setLanguage: (newLanguage: Language) => {
    throw new Error(translations[Language.English].functionNotImplemented);
  },
  setLoading: (newLoading: boolean, newMessage?: string) => {
    throw new Error(translations[Language.English].functionNotImplemented);
  },
  setUrl: (url: string) => {
    throw new Error(translations[Language.English].functionNotImplemented);
  },
  setLocale: (newLocale: Locale) => {
    throw new Error(translations[Language.English].functionNotImplemented);
  },
};

const AuthContext = createContext<IUseAuthValues>(defaultState);
export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(defaultState.loading);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>();
  const [language, setLanguage] = useState<Language>(defaultState.language);
  const [url, setUrl] = useState<string>("");
  const [locale, setLocale] = useState<Locale>(defaultState.locale);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    defaultState.isAuthenticated
  );
  const [userRole, setUserRole] = useState<UserRole | undefined>(UserRole.None);
  const [userPersonId, setUserPersonId] = useState<number | undefined>(
    undefined
  );
  const [userBrandId, setUserBrandId] = useState<number | undefined>(undefined);

  useEffect(() => {
    fetchInitialLanguage();
    checkIsAuthenticated();
  }, []);

  const checkIsAuthenticated = async (): Promise<void> => {
    const isAuth = await authService.isAuthenticated();

    if (isAuth !== isAuthenticated) {
      setIsAuthenticated(isAuth);
      const user = await authService.getUser();

      if (user?.sub) {
        const authorizedUser = await getAuthorizedPersonDetails(user.sub);

        if (authorizedUser && authorizedUser?.roles.length > 0) {
          setUserPersonId(authorizedUser.personId);
          setUserBrandId(authorizedUser.brandId);
          setUserRole(getRoleFromRoleText(authorizedUser.roles[0]));
        } else {
          setUserPersonId(undefined);
          setUserBrandId(undefined);
          setUserRole(UserRole.None);
        }
      } else {
        setUserPersonId(undefined);
        setUserBrandId(undefined);
        setUserRole(UserRole.None);
      }
    }
  };

  const getAuthorizedPersonDetails = async (
    id: number
  ): Promise<AuthorizedUserModel | undefined> => {
    let resp: AuthorizedUserModel | undefined = undefined;

    if (!process.env.REACT_APP_API_HOST) return;

    const token = await authService.getAccessToken();

    if (token) {
      await axios
        .get<AuthorizedUserModel>(
          `${process.env.REACT_APP_API_HOST}${`/Authorize/user/${id}`}`,
          getGenericRequestConfig(token)
        )
        .then((response) => {
          resp = response.data;
        })
        .catch((reason) => {
          if (reason.code === "ERR_NETWORK") {
            debugger;
            navigate(ApplicationPaths.Login);
          }
        });

      return resp;
    } else {
      debugger;
      navigate(ApplicationPaths.Login);
    }

    return resp;
  };

  async function get<T>(url: string): Promise<T | undefined> {
    let resp: T | undefined = undefined;

    if (!process.env.REACT_APP_API_HOST) return;

    const token = await authService.getAccessToken();
    await checkIsAuthenticated();

    if (token) {
      await axios
        .get<T>(
          `${process.env.REACT_APP_API_HOST}${url}`,
          getGenericRequestConfig(token)
        )
        .then((response) => {
          resp = response.data;
        })
        .catch((reason) => {
          if (reason.code === "ERR_NETWORK") {
            debugger;
            navigate(ApplicationPaths.Login);
          }
        });

      return resp;
    } else {
      debugger;
      navigate(ApplicationPaths.Login);
    }
  }

  async function post<T>(
    url: string,
    body: any,
    hideNotification?: boolean
  ): Promise<T | undefined> {
    let resp: T | undefined = undefined;

    if (!process.env.REACT_APP_API_HOST) return;

    const token = await authService.getAccessToken();
    await checkIsAuthenticated();

    if (token) {
      await axios
        .post<T>(
          `${process.env.REACT_APP_API_HOST}${url}`,
          body,
          getGenericRequestConfig(token)
        )
        .then((response) => {
          resp = response.data;
          if (hideNotification !== true) {
            notification.success({
              key: "create-notification",
              message: translations[language].successfulSaving,
              placement: "top",
              duration: 1.5,
            });
          }
        })
        .catch((reason) => {
          if (reason.code === "ERR_NETWORK") {
            debugger;
            navigate(ApplicationPaths.Login);
          }
        });

      return resp;
    } else {
      debugger;
      navigate(ApplicationPaths.Login);
    }
  }

  async function postFile<T>(
    url: string,
    files: File[]
  ): Promise<T | undefined> {
    const formData = new FormData();
    files.forEach((file: File): void => {
      formData.append("files", file);
    });

    let resp: T | undefined = undefined;

    if (!process.env.REACT_APP_API_HOST) return;

    const token = await authService.getAccessToken();
    await checkIsAuthenticated();

    if (token) {
      await axios
        .post<T>(
          `${process.env.REACT_APP_API_HOST}${url}`,
          formData,
          getGenericRequestConfig(token)
        )
        .then((response) => {
          resp = response.data;
          notification.success({
            key: "create-file-notification",
            message: translations[language].successfulSaving,
            placement: "top",
            duration: 1.5,
          });
        })
        .catch((reason) => {
          if (reason.code === "ERR_NETWORK") {
            debugger;
            navigate(ApplicationPaths.Login);
          }
        });

      return resp;
    } else {
      debugger;
      navigate(ApplicationPaths.Login);
    }

    return resp;
  }

  async function put<T>(url: string, body: any): Promise<T | undefined> {
    let resp: T | undefined = undefined;

    if (!process.env.REACT_APP_API_HOST) return;

    const token = await authService.getAccessToken();
    await checkIsAuthenticated();

    if (token) {
      await axios
        .put<T>(
          `${process.env.REACT_APP_API_HOST}${url}`,
          body,
          getGenericRequestConfig(token)
        )
        .then((response) => {
          resp = response.data;
          notification.success({
            key: "update-notification",
            message: translations[language].successfulSaving,
            placement: "top",
            duration: 1.5,
          });
        })
        .catch((reason) => {
          if (reason.code === "ERR_NETWORK") {
            debugger;
            navigate(ApplicationPaths.Login);
          }
        });

      return resp;
    } else {
      debugger;
      navigate(ApplicationPaths.Login);
    }
  }

  async function deleteEntity<T>(url: string): Promise<T | undefined> {
    let resp: T | undefined = undefined;

    if (!process.env.REACT_APP_API_HOST) return;

    const token = await authService.getAccessToken();
    await checkIsAuthenticated();

    if (token) {
      await axios
        .delete<T>(
          `${process.env.REACT_APP_API_HOST}${url}`,
          getGenericRequestConfig(token)
        )
        .then((response) => {
          resp = response.data;
          notification.success({
            key: "delete-notification",
            message: translations[language].successfulDeleting,
            placement: "top",
            duration: 1.5,
          });
        })
        .catch((reason) => {
          if (reason.code === "ERR_NETWORK") {
            debugger;
            navigate(ApplicationPaths.Login);
          }
        });

      return resp;
    } else {
      debugger;
      navigate(ApplicationPaths.Login);
    }
  }

  const fetchInitialLanguage = (): void => {
    const defaultLanguage = localStorage.getItem("language");

    if (defaultLanguage) {
      setLanguage(defaultLanguage as Language);
    } else {
      const newLang = Language.Croatian;
      setLanguage(newLang);
      localStorage.setItem("language", newLang);
    }
  };

  const updateLanguage = (newValue: Language): void => {
    localStorage.setItem("language", newValue);
    setLanguage(newValue);
  };

  const setLoadingHelper = (newState: boolean, message?: string): void => {
    if (newState) {
      setLoadingMessage(message || translations[language].loading);
      setLoading(true);
    } else {
      setLoading(false);
      setLoadingMessage(undefined);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        language,
        url,
        locale,
        userRole,
        userPersonId,
        userBrandId,
        isAuthenticated,
        get,
        post,
        postFile,
        put,
        remove: deleteEntity,
        setLanguage: updateLanguage,
        setLoading: setLoadingHelper,
        setUrl,
        setLocale,
      }}
    >
      {props.children}
      {loadingMessage && loading && (
        <LoadingOverlay customMessage={loadingMessage} />
      )}
    </AuthContext.Provider>
  );
};

const getGenericRequestConfig = (token?: string): any => ({
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});
